// import { HomePageTwo, GetStaticProps } from "../varejo-nextv3/HomePageTwo";

import { GetStaticProps } from "next";
import withHeader from "@/utils/withHeader";
import { calls } from "@/core-nextv3/util/call.api";
import { BannerHome } from "@/Components/BannerHome";
import { getProduct } from "@/core-nextv3/product/product.api";
import { HOME_TWO_PAGE_SETTING, THEME_SETTING } from "@/setting/setting";

// export default HomePageTwo; 

// export const getStaticProps = GetStaticProps; 

export default function HomePage({ homePage }: any) {
    return(
        <BannerHome images={homePage?.bannersHome} />
    );
}

export const getStaticProps: GetStaticProps = () => withHeader(async () => 
    {
        // HOME
        const [homePage ] = await calls(
            getProduct(HOME_TWO_PAGE_SETTING),
        );
    
        return {
            revalidate : THEME_SETTING.revalidate,
            props: {
                homePage		 : homePage?.data   || [],
            },
        };
    });