import styles from "./styles.module.scss";
import { useState } from "react";
import { Options } from '@splidejs/splide';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useResponsive } from "@/core-nextv3/util/useResponsive";

export const BannerHome = ({ images }: any) => {
    const { isDesktop }                                           = useResponsive();

    const mainOptions : Options = {
        autoplay     : true,
        rewind       : true,
        interval     : 5000,
        perPage      : 1,
        perMove      : 1,
        start        : 0,
        pauseOnHover : false,
        arrows       : false,
        pagination   : false,
        breakpoints: {
            1024: {
                perPage: 1,
            },
        }
    };

    return(
        <div className={styles.bannerHome}>
            <Splide
                options={mainOptions}
                aria-labelledby=""
                // ref={mainRef}
                className={styles.carouselBanners}
            >
                {images?.map((image: any, index: any) => (
                    <SplideSlide key={index}>
                        <div className={styles.images}>
                            {isDesktop &&
                                <img
                                    alt=""
                                    key={index}
                                    className={styles.imageTop}
                                    src={image?.desktop?._url}
                                />
                            }

                            {!isDesktop &&
                                <img
                                    alt=""
                                    key={index}
                                    className={styles.imageTop}
                                    src={image?.mobile?._url}
                                />
                            }
                        </div>
                    </SplideSlide>
                ))}
            </Splide>
        </div>
    );
}